import { Injectable } from '@angular/core';

@Injectable()
export class NumberUtils {
  constructor() { }

  //保留指定的小数位num为需要处理的数据,n表示保留的位数
  public static formatNumber(num: number, n: number, type?) {
    num = (num) ? parseFloat(num.toString()) : 0;
    if (num && n !== 8) {
      num = parseFloat(num.toFixed(8));
    }
    let numbString = num.toString();
    let index = numbString.indexOf('.');
    let numb = numbString.split('');
    if (index !== -1 && numb.length >= (index + n + 1)) { //若是决定进位为为5,修改为6,使其能正常进位,不受银行家算法的影响
      let indexRound = Math.round(index + n + 1);
      if (numb[indexRound] === '5') {
        numb[indexRound] = '6';
        num = Number(numb.join(''));
      }
    }
    num = num * 1;
    return parseFloat(num.toFixed(n));
  }

  //强制保留n位小数,不进行四舍五入(即自n+1开始全部舍去;当位数不足n位时,不补0填充)
  public static formatFloorNumber(num: number, n: number) {
    let numString = num.toString();
    let index = num.toString().indexOf('.');
    if (index !== -1) {
      let sub = index + n + 1;
      numString = num.toString().substring(0, sub);
    }
    return Number(numString);
  }


  //进一保留n位小数(若n+1位存在且不为0,则向前进一;当位数不足n位时,不补0填充)
  public static formatCeilNumber(num: number, n: number) {
    let numString = num.toString();
    let index = num.toString().indexOf('.');
    if (index !== -1) {
      let sub = index + n + 1;
      let indexString = numString.charAt(sub);//获取第n+1的值,决定是否进位
      if (indexString !== '' && indexString !== '0') {
        numString = num.toString().substring(0, sub);
        let numb = numString.split('');
        numb[index + n] = (Number(numb[index + n]) + 1).toString();
        numString = numb.join('');
      }
    }
    return Number(numString);
  }

  // Keep two decimal places after the decimal
  public static formatTwoDigit(num) {
    let s = num.toString();
    let dotIndex = s.indexOf('.');
    if (dotIndex < 0) {
      dotIndex = s.length;
      s += '.';
    }
    while (s.length <= dotIndex + 2) {
      s += '0';
    }
    return s;
  }

}
