import {Injectable} from '@angular/core';
import {Response} from '../models/response';
import {HttpService} from './http.service';
import {DateService} from '../utils/date.service';
import {Order} from '../models/order';
import {OrderLine} from '../models/orderLine';
import { Pagination } from "../models/pagination";
import { promise } from 'protractor';


@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http: HttpService) {
  }
  static toOrderModel(res) {
    return new Order({
      createdDate: DateService.getDateAndTimeString(DateService.utcToLocal(res['CreatedDate'])),
      orderGuid: res['OrderGuid'],
      ecrrefNum: res['ECRRefNum'],
      totalAmt: res['TotalAmt'],
      subTotal: res['SubTotal'],
      taxAmt: res['TaxAmt'],
      discountAmt: res['DiscountAmt'],
      tipAmt: res['TipAmt'],
      surchargeAmt: res['SurchargeAmt'],
      orderName: res['OrderName'],
      originalOrderGuid: res['OriginalOrderGuid'],
      orderStatus: res['OrderStatus'],
      paymentStatus: res['PaymentStatus'],
      refundedAmt: res['RefundedAmt'],
      nonCashAmt: res['NonCashAmt'],
      deliveryAmt: res['DeliveryAmt'],
      specialInstructions: res['SpecialInstructions'],
      createdBy: res['CreatedBy'],
      orderLines: OrderService.toOrderLinesModel(res['OrderLines']),
    });
  }

  static toOrderLinesModel(OrderLineList) {
    if (!OrderLineList || !OrderLineList.length) return [];
    const orderLines: Array<OrderLine> = [];
    for (const ord of OrderLineList) {
      const orderLine: OrderLine = new OrderLine();
      orderLine.guid = ord['ProductGuid'];
      orderLine.name = ord['ProductName'];
      orderLine.unitPriceIncTax = ord['UnitPriceIncTax'];
      orderLine.unitPriceExclTax = ord['UnitPriceExcTax'];
      orderLine.totalPriceIncTax = ord['TotalPriceIncTax'];
      orderLine.totalPriceExclTax = ord['TotalPriceExcTax'];
      orderLine.quantity = ord['Quantity'];
      orderLine.discountAmt = ord['DiscountAmt'],
      orderLine.totalTaxAmt = ord['TotalTaxAmt'];
      orderLine.note = ord['LineNote'];
      orderLine.sku = ord['ProductSKU'];
      orderLines.push(orderLine);
    }
    return orderLines;
  }

  // getOrderDetail(orderGuid: string): Promise<Response<Order>> {
  //   let response: Response<Order> = new Response<Order>();
  //   return new Promise((resolve, reject) => {
  //     this.http.get('/Merchant/Order/GetOrderDetail', {restful: {OrderGuid: orderGuid}}).subscribe(res => {
  //       response.data = OrderService.toOrderModel(res);
  //       resolve(response);
  //     }, error => reject(error));
  //   });
  // }

  getOrderslist(params): Promise<Response<Pagination<any>>> {
    let response: Response<any> =  new Response<any>()
    return new Promise((resolve, reject) => {
      this.http.get('/support/Orders', {
        restful: params
      }).subscribe(res => {
        let page: Pagination<any> = new Pagination<any>(res)
        page.data = res.orderHistory
        page.totalCounts = res.count;
        response.data = page
        resolve(response);
      }, error =>  reject(error))
    });
  }

  getOrderDetail(params): Promise<Response<any>> {
    let response: Response<any> =  new Response<any>()
    return new Promise((resolve, reject) => {
      this.http.get('/support/Orders/' + params).subscribe(res => {
        response.data = {
          ...res
        }
        resolve(response);
      }, error =>  reject(error))
    });
  }

  updateShippedInfo(params): Promise<Response<any>> {
    console.log(JSON.stringify(params))
    let response: Response<any> =  new Response<any>()
    return new Promise((resolve, reject) => {
      this.http.post('/support/Orders/Ship', {
        data: params
      }).subscribe(res => {
        response.data = {
          ...res
        }
        resolve(response);
      }, error =>  reject(error))
    })
  }

  
  updateOrderStatus(params): Promise<Response<any>> {
    let response: Response<any> =  new Response<any>()
    return new Promise((resolve, reject) => {
      this.http.put('/support/Orders/Refund', 
      {
        params: params,
        isOriginalData: true
      }).subscribe(res => {
        response.data = res
        resolve(response)
      }, error => reject(error))
    })
  }

  updateReturnItems(params): Promise<Response<any>> {
    console.log(JSON.stringify(params))
    let response: Response<any> =  new Response<any>()
    return new Promise((resolve, reject) => {
      this.http.post('/support/Return/UpdateReturnItems', {
        data: params
      }).subscribe(res => {
        response.data = {
          ...res
        }
        resolve(response);
      }, error =>  reject(error))
    })
  }

  AddReturnSNs(params): Promise<Response<any>> {
    console.log(JSON.stringify(params))
    let response: Response<any> =  new Response<any>()
    return new Promise((resolve, reject) => {
      this.http.post('/support/Return/AddReturnSNs', {
        data: params
      }).subscribe(res => {
        response.data = {
          ...res
        }
        resolve(response);
      }, error =>  reject(error))
    })
  }
}
