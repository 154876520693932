export class OrderLine {
  guid: string; // ConsumerShoppingCartGuid
  name: string; // ProductName
  quantity: number; // Quantity
  totalTaxAmt: number;
  discountAmt: number;

  note: string;
  sku: string;

  itemGuid: string; // ProductGuid
  itemStockQuantity: number; // Product Quantity
  itemInventoryMethod: string; // Product Inventory Method
  tax: string;

  unitPriceExclTax: number; // UnitPriceExclTax  单件 不含modifier、不含税
  unitPriceIncTax: string; // UnitPriceInclTax  单件 不含modifier、含税

  totalPriceExclTax: string; // TotalExclTax 一组 含modifier、不含税
  totalPriceIncTax: number; // TotalInclTax 一组 含modifier、含税

  // isActive: boolean;
  // pictureUrl: string;
  // linePriceExclTax: string; // PriceExclTax  单行 不含modifier、不含税
  // linePriceInclTax: string; // PriceInclTax  单行 不含modifier、含税
  // attributes: Array<AttributeValue>; // Attributes name>Value  > Price
  // attrComb: AttributeCombination;
  // modifier: Array<OrderLine>;
  // attributeCombinations: Array<AttributeCombination>;

  constructor(options: {
    guid?: string;
    name?: string;
    quantity?: number;
    totalTaxAmt?: number;
    note?: string;
    sku?: string;
    itemGuid?: string;
    unitPriceExclTax?: number;
    unitPriceIncTax?: string;
    totalPriceExclTax?: string;
    totalPriceIncTax?: number;

    // isActive?: boolean;
    // pictureUrl?: string;
    // modifier?: Array<OrderLine>;
    // linePriceExclTax?: string;
    // linePriceInclTax?: string;
  } = {}) {
    this.guid = options.guid || '';
    this.name = options.name || '';
    this.quantity = options.quantity || null;
    this.totalTaxAmt = options.totalTaxAmt || null;
    this.note = options.note || '';
    this.itemGuid = options.itemGuid || '';
    this.unitPriceExclTax = options.unitPriceExclTax || null;
    this.unitPriceIncTax = options.unitPriceIncTax || null;
    this.totalPriceExclTax = options.totalPriceExclTax || null;
    this.totalPriceIncTax = options.totalPriceIncTax || null;

    // this.pictureUrl = options.pictureUrl || '';
    // this.isActive = options.isActive || true;
    // this.linePriceExclTax = options.linePriceExclTax || null;
    // this.linePriceInclTax = options.linePriceInclTax || null;
  }
}

